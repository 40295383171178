import React from 'react';
import AdSense from 'react-adsense';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

// TODO replace react-adsense with @ctrl/react-adsense
// react-adsense does not have any typings
function GoogleAdSense(props) {
  const fallbackRender = React.useCallback(({ error }) => {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }, []);

  return (
    <ReactErrorBoundary fallbackRender={fallbackRender}>
      <AdSense.Google {...props} />
    </ReactErrorBoundary>
  );
}

export default GoogleAdSense;
