import ClearIcon from '@mui/icons-material/Clear';
import HelpIcon from '@mui/icons-material/Help';
import ImportIcon from '@mui/icons-material/ImportExport';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Hidden,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { findLastIndex } from 'lodash';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Link from '../../navigation/Link';
import { MIST_ATTUNEMENTS } from './RelicsData';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  helpIcon: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dialogButtonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  errorMessage: {
    flexGrow: 1,
    color: theme.palette.error.main,
    fontFamily: 'Fira Mono',
    lineHeight: 1,
    fontSize: '0.875rem',
    '&:first-child': {
      marginLeft: theme.spacing(2.5),
    },
  },
}));

const GW2_DOMAIN = 'https://api.guildwars2.com/v2/';

const WALLET_RELICS_ID = 7;
const WALLET_PRISTINES_ID = 24;

const STORAGE_MATRICES_ID = 79230;
const STORAGE_PAGES_ID = 73834;
const STORAGE_JOURNALS_ID = 75439;

const LOCAL_STORAGE_KEY = 'discretize:augmentationsCalculator:apiKey';

function RelicsCalculatorImport(props) {
  const { classes } = useStyles();

  const [state, setState] = React.useState({
    open: false,
    apiKey: '',
    saveLocally: true,

    isSubmitting: false,
    error: '',
  });

  const handleChange =
    (name, boolean = false) =>
    ({ target: { value, checked } }) =>
      setState({ ...state, [name]: boolean ? checked : value });

  const handleClickOpen = () => {
    setState({ ...state, open: true });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const relicsFromWallet = (wallet) => {
    const { value: relics = 0 } =
      wallet.find(({ id }) => id === WALLET_RELICS_ID) || {};
    const { value: pristines = 0 } =
      wallet.find(({ id }) => id === WALLET_PRISTINES_ID) || {};

    return { relics: relics || 0, pristines: pristines || 0 };
  };

  const augmentFromTitles = (titles) =>
    Math.max(
      findLastIndex(MIST_ATTUNEMENTS, ({ id }) => titles.includes(id)) + 1,
      0,
    );

  const readStorage = (bank, storage) => {
    const matrices =
      ((
        bank
          .filter((value) => !!value)
          .find(({ id }) => id === STORAGE_MATRICES_ID) || {}
      ).count || 0) +
      ((
        storage
          .filter((value) => !!value)
          .find(({ id }) => id === STORAGE_MATRICES_ID) || {}
      ).count || 0);
    const pages =
      ((
        bank
          .filter((value) => !!value)
          .find(({ id }) => id === STORAGE_PAGES_ID) || {}
      ).count || 0) +
      ((
        storage
          .filter((value) => !!value)
          .find(({ id }) => id === STORAGE_PAGES_ID) || {}
      ).count || 0);
    const { count: journals } =
      bank
        .filter((value) => !!value)
        .find(({ id }) => id === STORAGE_JOURNALS_ID) || {};

    return {
      matrices: matrices || 0,
      pages: pages || 0,
      journals: journals || 0,
    };
  };

  const importFromApi = async (apiKey) => {
    const { onImport } = props;
    const { isSubmitting } = state;

    if (isSubmitting) {
      return;
    }

    setState({ ...state, isSubmitting: true, error: '' });
    try {
      const authHeader = {
        params: {
          access_token: apiKey,
        },
      };

      const [titles, wallet, storage, bank] = await Promise.all([
        axios.get(`${GW2_DOMAIN}account/titles`, {
          ...authHeader,
        }),
        axios.get(`${GW2_DOMAIN}account/wallet`, {
          ...authHeader,
        }),
        axios.get(`${GW2_DOMAIN}account/materials`, {
          ...authHeader,
        }),
        axios.get(`${GW2_DOMAIN}account/bank`, {
          ...authHeader,
        }),
      ]);

      onImport({
        ...relicsFromWallet(wallet.data),
        ...readStorage(bank.data, storage.data),
        augment: augmentFromTitles(titles.data),
      });

      setState({ ...state, isSubmitting: false, error: '' });
    } catch (error) {
      let message;

      if (error.response && error.response.status === 403) {
        message = 'Insufficient permissions, check your API key';
      } else if (error.response && error.response.status === 429) {
        message = 'Too many requests, retry later';
      } else if (error.response && error.response.status === 500) {
        message = 'API error - maybe try again';
      } else {
        const { message: msg } = error;
        message = msg;
      }

      setState({ ...state, isSubmitting: false, error: message });
      throw error;
    }
    setState({ ...state, apiKey });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { apiKey, saveLocally } = state;

    if (apiKey && saveLocally) {
      localStorage.setItem(LOCAL_STORAGE_KEY, apiKey);
    }

    try {
      await importFromApi(apiKey);
      handleClose();
    } catch (error) {
      // Noop
    }
  };

  React.useEffect(() => {
    // reimport in case there was a key stored on the disk
    const apiKey = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (apiKey) {
      importFromApi(apiKey);
    }
  }, []);

  const { open, apiKey, saveLocally, isSubmitting, error } = state;

  return (
    <>
      <div className={classes.root}>
        {open || !apiKey ? (
          <Button variant="outlined" size="small" onClick={handleClickOpen}>
            <ImportIcon className={classes.icon} />
            Import data
          </Button>
        ) : (
          <>
            <Button
              variant="outlined"
              size="small"
              onClick={() => importFromApi(apiKey)}
              disabled={isSubmitting}
            >
              <RefreshIcon className={classes.icon} />
              Refresh data
              {isSubmitting && (
                <CircularProgress
                  size={24}
                  className={classes.dialogButtonProgress}
                />
              )}
            </Button>

            <Hidden smDown>
              <Tooltip title={`API key: ${apiKey}`} enterDelay={100}>
                <HelpIcon
                  color="inherit"
                  fontSize="small"
                  className={classes.helpIcon}
                />
              </Tooltip>
            </Hidden>

            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                localStorage.removeItem(LOCAL_STORAGE_KEY);
                setState({ ...state, apiKey: '', error: '' });
              }}
              disabled={isSubmitting}
            >
              <ClearIcon className={classes.icon} />
              Clear API key
            </Button>
          </>
        )}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="api-import-dialog"
        >
          <form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle id="api-import-dialog">Import from API</DialogTitle>

            <DialogContent>
              <DialogContentText>
                To import your relevant account values, please enter your{' '}
                <Link to="https://account.arena.net/applications">
                  Guild Wars 2 API key
                </Link>{' '}
                here. You need the <code>inventories</code>,{' '}
                <code>unlocks</code> and <code>wallet</code> permissions.
              </DialogContentText>

              <TextField
                id="api-key"
                autoFocus
                margin="normal"
                label="API Key"
                fullWidth
                onChange={handleChange('apiKey')}
                value={apiKey}
                helperText="Only materials from the bank and storage are imported."
              />

              <FormControlLabel
                control={
                  <Checkbox
                    id="save-locally"
                    checked={saveLocally}
                    color="primary"
                    value="saveLocally"
                    onChange={handleChange('saveLocally', true)}
                  />
                }
                label="Save key locally"
              />
            </DialogContent>

            <DialogActions>
              {error && (
                <Typography component="span" className={classes.errorMessage}>
                  {error}
                </Typography>
              )}

              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                type="submit"
              >
                Import
                {isSubmitting && (
                  <CircularProgress
                    size={24}
                    className={classes.dialogButtonProgress}
                  />
                )}
              </Button>

              <Button onClick={handleClose} disabled={isSubmitting}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>

      {!open && error && (
        <Typography component="span" className={classes.errorMessage}>
          {error}
        </Typography>
      )}
    </>
  );
}

export default RelicsCalculatorImport;
