import {
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import classNames from 'classnames'
import React from 'react'
import { Journal, Matrix, Page, Pristine, Relic } from './RelicsData'

const useStyles = makeStyles()((theme) => ({
  listItem: {
    padding: `${theme.spacing(0.5)} 0`,
  },
  listItemNumeric: {
    textAlign: 'right',
  },
  listItemText: {
    whiteSpace: 'nowrap',
    padding: 0,
  },
}))

function RelicsOutputTable({ mistAttunements }) {
  const { classes } = useStyles()
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Tier</TableCell>
          <TableCell>Tier Cost</TableCell>
          <TableCell>Total Cost</TableCell>
          <TableCell>Normal Duration</TableCell>
          <TableCell>Ideal Conversions</TableCell>
          <TableCell>Ideal Duration</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {mistAttunements.map(
          ({
            name,
            title,
            days,
            standard: {
              daysForRelics,
              daysForPristines,
              daysForMatrices,
              daysForJournals,
            },
            convert: { pristinesToRelics, relicsToMatrices, pagesToJournals },
            relics,
            pristines,
            matrices,
            journals,
            total: {
              relics: totalRelics,
              pristines: totalPristines,
              matrices: totalMatrices,
              journals: totalJournals,
            },
          }) => (
            <TableRow key={name} hover>
              <TableCell>
                <List dense>
                  <ListItem className={classes.listItem}>
                    <ListItemText className={classes.listItemText}>
                      {name}
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemText className={classes.listItemText}>
                      ({title})
                    </ListItemText>
                  </ListItem>
                </List>
              </TableCell>

              <TableCell>
                <List dense>
                  {relics > 0 && (
                    <ListItem
                      className={classNames(
                        classes.listItem,
                        classes.listItemNumeric,
                      )}
                    >
                      <ListItemText className={classes.listItemText}>
                        {relics.toLocaleString()} <Relic disableText />
                      </ListItemText>
                    </ListItem>
                  )}

                  {pristines > 0 && (
                    <ListItem
                      className={classNames(
                        classes.listItem,
                        classes.listItemNumeric,
                      )}
                    >
                      <ListItemText className={classes.listItemText}>
                        {pristines.toLocaleString()} <Pristine disableText />
                      </ListItemText>
                    </ListItem>
                  )}

                  {matrices > 0 && (
                    <ListItem
                      className={classNames(
                        classes.listItem,
                        classes.listItemNumeric,
                      )}
                    >
                      <ListItemText className={classes.listItemText}>
                        {matrices.toLocaleString()} <Matrix disableText />
                      </ListItemText>
                    </ListItem>
                  )}

                  {journals > 0 && (
                    <ListItem
                      className={classNames(
                        classes.listItem,
                        classes.listItemNumeric,
                      )}
                    >
                      <ListItemText className={classes.listItemText}>
                        {journals.toLocaleString()} <Journal disableText />
                      </ListItemText>
                    </ListItem>
                  )}
                </List>
              </TableCell>

              <TableCell>
                <List dense>
                  {totalRelics > 0 && (
                    <ListItem
                      className={classNames(
                        classes.listItem,
                        classes.listItemNumeric,
                      )}
                    >
                      <ListItemText className={classes.listItemText}>
                        {totalRelics.toLocaleString()} <Relic disableText />
                      </ListItemText>
                    </ListItem>
                  )}

                  {totalPristines > 0 && (
                    <ListItem
                      className={classNames(
                        classes.listItem,
                        classes.listItemNumeric,
                      )}
                    >
                      <ListItemText className={classes.listItemText}>
                        {totalPristines.toLocaleString()}{' '}
                        <Pristine disableText />
                      </ListItemText>
                    </ListItem>
                  )}

                  {totalMatrices > 0 && (
                    <ListItem
                      className={classNames(
                        classes.listItem,
                        classes.listItemNumeric,
                      )}
                    >
                      <ListItemText className={classes.listItemText}>
                        {totalMatrices.toLocaleString()} <Matrix disableText />
                      </ListItemText>
                    </ListItem>
                  )}

                  {totalJournals > 0 && (
                    <ListItem
                      className={classNames(
                        classes.listItem,
                        classes.listItemNumeric,
                      )}
                    >
                      <ListItemText className={classes.listItemText}>
                        {totalJournals.toLocaleString()} <Journal disableText />
                      </ListItemText>
                    </ListItem>
                  )}
                </List>
              </TableCell>

              <TableCell>
                <List dense>
                  {daysForRelics > 0 && (
                    <ListItem
                      className={classNames(
                        classes.listItem,
                        classes.listItemNumeric,
                      )}
                    >
                      <ListItemText className={classes.listItemText}>
                        {Math.ceil(daysForRelics).toLocaleString()} days for{' '}
                        <Relic disableText />
                      </ListItemText>
                    </ListItem>
                  )}

                  {daysForPristines > 0 && (
                    <ListItem
                      className={classNames(
                        classes.listItem,
                        classes.listItemNumeric,
                      )}
                    >
                      <ListItemText className={classes.listItemText}>
                        {Math.ceil(daysForPristines).toLocaleString()} days for{' '}
                        <Pristine disableText />
                      </ListItemText>
                    </ListItem>
                  )}

                  {daysForMatrices > 0 && (
                    <ListItem
                      className={classNames(
                        classes.listItem,
                        classes.listItemNumeric,
                      )}
                    >
                      <ListItemText className={classes.listItemText}>
                        {Math.ceil(daysForMatrices).toLocaleString()} days for{' '}
                        <Matrix disableText />
                      </ListItemText>
                    </ListItem>
                  )}

                  {daysForJournals > 0 && (
                    <ListItem
                      className={classNames(
                        classes.listItem,
                        classes.listItemNumeric,
                      )}
                    >
                      <ListItemText className={classes.listItemText}>
                        {Math.ceil(daysForJournals).toLocaleString()} days for{' '}
                        <Journal disableText />
                      </ListItemText>
                    </ListItem>
                  )}
                </List>
              </TableCell>

              <TableCell>
                {(pristinesToRelics > 0 ||
                  relicsToMatrices > 0 ||
                  pagesToJournals > 0) && (
                  <List dense>
                    {pristinesToRelics > 0 && (
                      <ListItem
                        className={classNames(
                          classes.listItem,
                          classes.listItemNumeric,
                        )}
                      >
                        <ListItemText className={classes.listItemText}>
                          {pristinesToRelics % 1 !== 0 && '~'}
                          {Math.ceil(pristinesToRelics).toLocaleString()}{' '}
                          <Pristine disableText /> to <Relic disableText />
                        </ListItemText>
                      </ListItem>
                    )}

                    {relicsToMatrices > 0 && (
                      <ListItem
                        className={classNames(
                          classes.listItem,
                          classes.listItemNumeric,
                        )}
                      >
                        <ListItemText className={classes.listItemText}>
                          {relicsToMatrices % 1 !== 0 && '~'}
                          {Math.ceil(relicsToMatrices).toLocaleString()}{' '}
                          <Relic disableText /> to <Matrix disableText />
                        </ListItemText>
                      </ListItem>
                    )}

                    {pagesToJournals > 0 && (
                      <ListItem
                        className={classNames(
                          classes.listItem,
                          classes.listItemNumeric,
                        )}
                      >
                        <ListItemText className={classes.listItemText}>
                          {pagesToJournals % 1 !== 0 && '~'}
                          {Math.ceil(pagesToJournals).toLocaleString()}{' '}
                          <Page disableText /> to <Journal disableText />
                        </ListItemText>
                      </ListItem>
                    )}
                  </List>
                )}
              </TableCell>

              <TableCell>{Math.ceil(days).toLocaleString()} days</TableCell>
            </TableRow>
          ),
        )}
      </TableBody>
    </Table>
  )
}

export default RelicsOutputTable
