import { Divider } from '@mui/material';
import React from 'react';
import RelicsCalculator from '../../components/pages/augmentation-calculator/RelicsCalculator';
import GoogleAdSense from '../../components/GoogleAdSense';
import Layout from '../../components/Layout';
import GuideHeader from '../../components/navigation/GuideHeader';
import SeoHeader from '../../components/SeoHeader';

const seo = {
  title: 'Augmentations Calculator',
  description: 'Calculate how long it will take you to become a Fractal God.',
};

function AugmentationsCalculatorPage({ location }) {
  return (
    <Layout location={location}>
      <SeoHeader path={location.pathname} {...seo} />
      <GuideHeader title="Augmentations Calculator" />
      <Divider />
      {process.env.GATSBY_CA_PUB_ID && (
        <GoogleAdSense
          client={process.env.GATSBY_CA_PUB_ID}
          slot="3008893292"
          format="fluid"
          layout="in-article"
          style={{
            display: 'block',
            minWidth: 250,
            textAlign: 'center',
            marginBottom: 16,
          }}
        />
      )}
      <RelicsCalculator />
    </Layout>
  );
}

export default AugmentationsCalculatorPage;
